
.row-content .heading-block {
    margin-bottom: 20px;
}
.row-content .heading-block span {
    margin-bottom: 20px;
}
.row-content .heading-block:after {
    margin-top: 10px;
}
.row-content .heading {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.row-content .picture {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.row-content img {
    max-height: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.row-content .heading .heading-title {
    font-size:60px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 46px;
    color: var(--secondary-color);
}
.row-content .heading h1 {
    font-size:39px;
    margin-top:0px;
    margin-bottom: 10px;
    line-height: 46px;
    color: #333;
}
.row-content .heading h2 {
    font-weight: 300;
    font-size: 1.25rem;
}
.row-content div.text {
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 30px;
}
.row-content div.text p {
    margin-bottom: 10px;
}
.row-content div.text ul {
    list-style-type: none;
    margin-left:10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.row-content div.text ul ul {
    margin-left:20px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.row-content div.text ul li:not(:first-child) {
    margin-top: 5px;
}
.row-content div.text ul li:not(:last-child) {
    padding-bottom: 5px;
}
.row-content div.text ul li:before {
    font-family: "font-icons";
    content: "\e77d"; /* "\e6c8"; "\e71e" */
    color: var(--secondary-color);
    margin-right: 10px;
}

